/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.features-boxed {
  color: #313437;
  background-color: #7D110C;
}

  .features-boxed p {
    color: #7d8285;
  }

  .features-boxed h2 {
    font-weight: bold;
    margin-bottom: 40px;
    padding-top: 40px;
    color: inherit;
  }

@media (max-width:767px) {
  .features-boxed h2 {
    margin-bottom: 25px;
    padding-top: 25px;
    font-size: 24px;
  }
}

.features-boxed .intro {
  font-size: 16px;
  max-width: 500px;
  margin: 0 auto;
}

  .features-boxed .intro p {
    margin-bottom: 0;
  }

.features-boxed .features {
  padding: -5px 0;
}

.features-boxed .item {
  text-align: center;
}

  .features-boxed .item .box {
    text-align: center;
    padding: 10px;
    /*background-color: #fff;*/
    margin-bottom: 30px;
  }

  .features-boxed .item .icon {
    font-size: 60px;
    color: #1485ee;
    margin-top: 20px;
    margin-bottom: 35px;
  }

  .features-boxed .item .name {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 8px;
    margin-top: 0;
    color: inherit;
  }

  .features-boxed .item .description {
    font-size: 15px;
    margin-top: 15px;
    margin-bottom: 20px;
  }

.register-photo {
  background: #7d110c;
  padding: 10px 10px;
}

  .register-photo .image-holder {
    display: table-cell;
    width: auto;
    /*background: url(../../assets/images/pic_strip.png);*/
    background-size: cover;
  }

  .register-photo .form-container {
    display: table;
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.1);
  }

  .register-photo form {
    display: table-cell;
    width: 400px;
    background-color: #ffffff;
    padding: 10px 20px;
    color: #505e6c;
  }

@media (max-width:991px) {
  .register-photo form {
    padding: 10px;
  }
}

.register-photo form h2 {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 30px;
}

.register-photo form .form-control {
  background: #f7f9fc;
  border: none;
  border-bottom: 1px solid #dfe7f1;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: inherit;
  text-indent: 6px;
  height: 40px;
}

.register-photo form .form-check {
  font-size: 13px;
  line-height: 20px;
}

.register-photo form .btn-primary {
  background: #f4476b;
  border: none;
  border-radius: 4px;
  padding: 11px;
  box-shadow: none;
  margin-top: 35px;
  text-shadow: none;
  outline: none !important;
}

  .register-photo form .btn-primary:hover, .register-photo form .btn-primary:active {
    background: #eb3b60;
  }

  .register-photo form .btn-primary:active {
    transform: translateY(1px);
  }

.register-photo form .already {
  display: block;
  text-align: center;
  font-size: 12px;
  color: #6f7a85;
  opacity: 0.9;
  text-decoration: none;
}

